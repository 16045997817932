import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const DemoIndexPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{}} showHeadline="true" centerHeadline="true">
        <Link link="/demo/1" className="btn">
          Seite 1
        </Link>
        <Link link="/demo/2" className="btn">
          Seite 2
        </Link>
        <Link link="/demo/workspace" className="btn">
          Workspace
        </Link>
      </BlockWrapper>
    </Layout>
  )
}

export default DemoIndexPage
